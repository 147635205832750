import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { getSearchQuery } from 'helpers';
import { PageTemplate } from 'gatsby-theme-ww-prismic/src/templates/page';
export default (function (_ref) {
  var data = _ref.data,
      location = _ref.location,
      props = _objectWithoutPropertiesLoose(_ref, ["data", "location"]);

  var _useState = useState(getSearchQuery('query', location)),
      query = _useState[0],
      setQuery = _useState[1];

  useEffect(function () {
    setQuery(getSearchQuery('query', location));
  }, [location]);

  var onSubmit = function onSubmit(_ref2) {
    var _ref2$query = _ref2.query,
        query = _ref2$query === void 0 ? '' : _ref2$query;
    navigate("/search/?" + queryString.stringify({
      query: query
    }));
  };

  var injectSlices = function injectSlices(list) {
    if (list === void 0) {
      list = [];
    }

    return [{
      __typename: 'PageBodyHeader',
      id: 'header'
    }].concat(_toConsumableArray(list), [{
      __typename: 'PageBodySearchBar',
      id: 'search-bar',
      initialQuery: query,
      onSubmit: onSubmit
    }, {
      __typename: 'PageBodySearchResults',
      id: 'search-results',
      query: query
    }, {
      __typename: 'PageBodyFooter',
      id: 'footer'
    }]);
  };

  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    listMiddleware: injectSlices
  }, props));
});
export var query = "1604691817";